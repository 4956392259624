export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  lazy: true,
  strategy: 'prefix_except_default',
  defaultLocale: 'en',
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: 'language',
    redirectOn: 'root',
    alwaysRedirect: true,
  },
  precompile: { strictMessage: false },
  compilation: { strictMessage: false },
  restructureDir: false,
  bundle: { optimizeTranslationDirective: false },
  debug: false,
}));
